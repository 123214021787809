"use server";

import { getProcurementTransactions } from "../apis/server_apis";
import { ProcurementTransactionResponse } from "../types/order";

export type ProcurementPurchaseRouteData = {
  purchaseOrders: ProcurementTransactionResponse;
};

export const getProcurementPurchaseRouteData =
  async (): Promise<ProcurementPurchaseRouteData> => {
    const purchaseOrders = await getProcurementTransactions();
    return { purchaseOrders };
  };
